import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Icon from '../../../../../utils/Icon';
import getMessage from '../../../../../utils/getMessage';
import CommentEditMenuButton from './CommentEditMenuButton';

const CommentFooterComponent = ({
                                  canReply,
                                  votesNum,
                                  handleToggleReplyEditor,
                                  onVote,
                                  showVotes = true,
                                  showEditToggler = false,
                                  votedByUser = false,
                                  editButtons,
                                  handleEdit,
                                  handleDelete,
                                  onUnPublishComment,
                                  onPublishComment,
                                  isRepliesOpen,
                                  renderHideReplyButton,
                                  renderViewReplyButton,
                                  isLastOpen,
                                }) => {

  /**
   * If a thread can be replied to, render reply links
   */
  const renderReplyLinks = () => (
    <button type="button" className="hearing-comment-action-button" 
      onClick={handleToggleReplyEditor} style={{ marginLeft: 6, fontWeight: 'bold' }}
    >
      <Icon name="reply" aria-hidden="true"/>
      <FormattedMessage id="reply" />
    </button>
  );

  const voteButtonText = votedByUser 
    ? getMessage('voteCancelButtonText') 
    : getMessage('voteButtonText');

  return (
    <div className="hearing-comment__actions-bar">
      <div className="hearing-comment__actions-bar__left">
        {canReply &&<div className="hearing-comment__reply-link">
          {renderReplyLinks()}
        </div>}
        
        { showVotes &&
        <div className="hearing-comment-votes">
          <button type="button"
            className="hearing-comment-vote-link hearing-comment-action-button"
            onClick={onVote}
            title={voteButtonText}
          >
            <Icon name="fa-solid fa-thumbs-up"  aria-hidden="true" className={votedByUser ? 'voted' : ''} /> {votesNum}
            <span className="sr-only">
              <FormattedMessage id="voteButtonLikes" />&nbsp;
              {voteButtonText}
            </span>
          </button>
        </div>
        }
        {showEditToggler && <div>
          <CommentEditMenuButton
            editButtons={editButtons}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onUnPublishComment={onUnPublishComment}
            onPublishComment={onPublishComment}
          />
        </div>}
      </div>

      {(isRepliesOpen && isLastOpen)
          ? renderHideReplyButton()
          : renderViewReplyButton()}
    </div>
  );
};

CommentFooterComponent.propTypes = {
  onVote: PropTypes.func,
  handleToggleReplyEditor: PropTypes.func,

  canReply: PropTypes.bool,
  votesNum: PropTypes.number,
};

export default CommentFooterComponent;