import React, { useEffect } from 'react';
import { cleanJumpTo, editSectionComment } from '../../../../../store/actions';
import { getUser } from '../../../../../store/selectors/user';
import PropTypes from 'prop-types';
import useComment from './useComment.hook';
import classnames from 'classnames';
import Sticker from './Sticker';
import CommentImageComponent from './CommentImageComponent';
import CommentFooterComponent from './CommentFooterComponent';
import { connect } from 'react-redux';
import {
  removeEditingReply,
  setIsEditingReply,
} from '../../../../../store/actions/commentEditorActions';
import {
  getCommentEditingStateById,
  getReplyEditingTextById,
} from '../../../../../store/selectors/commentEditorSelectors';
import IntersectionObserverContainer from './IntersectionObserverContainer';
import CommentBody from './Comment/CommentBody';
import classNames from 'classnames';

const Comment = ({
                   canReply,
                   canVote,
                   data,
                   defaultNickname,
                   hearing,
                   hearingId,
                   isReply,
                   jumpTo,
                   nicknamePlaceholder,
                   onDeleteComment,
                   onUnPublishComment,
                   onPublishComment,
                   onPostReply,
                   onPostVote,
                   questions,
                   sectionCommentId,
                   section,
                   user,
                   language,
                   onEditComment,
                   getCommentSubComments,
                   removeUrlFocusComment,
                   isEditing,
                   setIsEditingReply,
                   replayingText,
                   removeEditingReply,
                   newCommentParentChain = [],
                   newCommentIds = [],
                   handleCommentWasSeen,
                   cleanJumpTo,
                   openComments = [],
                   setOpenComments,
                   className = '',
                   parentIdList = [],
                 }
) => {
  const {
    isAuthorAdminUser,
    onVote,
    setPinned,
    setAnswers,
    editorOpen, setEditorOpen,
    shouldJumpTo, setShouldJumpTo,
    setShouldAnimate,
    scrollComplete, setScrollComplete,
    isRepliesOpen, //setIsRepliesOpen,
    isReplyEditorOpen, setIsReplyEditorOpen,
    mapContainer, setMapContainer,
    displayMap, setDisplayMap,
    handleToggleReplyEditor,
    renderCommentHeader,
    renderEditorForm,
    renderEditLinks,
    renderRemoveLink,
    renderReplyForm,
    renderViewReplyButton,
    handleSetMapContainer,
    toggleMap,
    shouldRenderDeleteForOthers,
    renderHideReplyButton,
    toggleEditor,
    handleDelete,
    handleUnPublish,
    handlePublish,
    showEditButtons,
    toggleShowEditButtons,
  } = useComment(openComments, setOpenComments, data.id, parentIdList);

  const commentRef = React.createRef();

  const commentIndex = openComments.findIndex(oc => oc === data.id);
  const lastOpenComment = commentIndex === openComments.length - 1;

  const isAncestor = commentIndex > -1 && commentIndex < openComments.length -1

  useEffect(() => {
    if (
      shouldJumpTo &&
      commentRef &&
      commentRef.current &&
      !scrollComplete
    ) {
      commentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        alignToTop: true,
      });
      cleanJumpTo(section.id);
      setScrollComplete(true);
      setShouldAnimate(true);
      removeUrlFocusComment();
    }
  }, [shouldJumpTo]);

  useEffect(() => {
    setShouldJumpTo(jumpTo === data.id);
  }, [jumpTo])

  useEffect(() => {
    setEditorOpen(isEditing);
  }, [isEditing]);

  useEffect(() => {
    setEditorOpen(isEditing);
    //setIsRepliesOpen(data.subComments);
    setIsReplyEditorOpen(!!replayingText);
    setScrollComplete(false);
    setShouldAnimate(false);
    setPinned(data.pinned);
    setAnswers(data.answers || []);
    setMapContainer(null);
    setDisplayMap(false);
  }, []);


  /**
   * Renders the sub comments
   * @returns {Component<Comment>} recursively renders comment component until last depth.
   */
  const renderSubComments = () => {
    const subComments = lastOpenComment
      ? data.subComments
      : data.subComments.filter(sc => sc.id === openComments[commentIndex+1])

    const className = lastOpenComment ? "sub-comments" : "commentlist"

    return (
      <ul className={className}>
        {subComments.map(subComment => (
            <ConnectedComment
              openComments={openComments}
              setOpenComments={setOpenComments}
              canReply={canReply}
              canVote={canVote}
              defaultNickname={defaultNickname}
              hearing={hearing}
              hearingId={hearingId}
              isReply={isReply}
              jumpTo={jumpTo}
              nicknamePlaceholder={nicknamePlaceholder}
              onDeleteComment={onDeleteComment}
              onUnPublishComment={onUnPublishComment}
              onPublishComment={onPublishComment}
              onPostReply={onPostReply}
              onPostVote={onPostVote}
              questions={questions}
              sectionCommentId={sectionCommentId}
              section={section}
              user={user}
              language={language}
              onEditComment={onEditComment}
              getCommentSubComments={getCommentSubComments}
              removeUrlFocusComment={removeUrlFocusComment}
              parentComponentId={data.id}
              data={subComment}
              key={`subcomment_${subComment.id}}`}
              setIsEditingReply={setIsEditingReply}
              removeEditingReply={removeEditingReply}
              isEditing={isEditing}
              newCommentParentChain={newCommentParentChain}
              newCommentIds={newCommentIds}
              handleCommentWasSeen = {handleCommentWasSeen}
              parentIdList={parentIdList.concat(data.id)}
            />
          ),
        )
        }
      </ul>
    );
  };

  const canEdit = data.can_edit;

  const hasSubComments = data.subComments &&
    Array.isArray(data.subComments) &&
    data.subComments.length > 0;

  const isOwnComment =
    data &&
    user &&
    (data.author_name === user.nickname ||
      data.author_name === user.username);

  const _setIsEditingReply = (value) => setIsEditingReply(data.id, section.id, value);
  const _cancelReplyEditing = () => removeEditingReply(data.id, section.id);

  const _renderEditorForm = () => renderEditorForm(data, isReply, sectionCommentId, hearing, onEditComment, removeUrlFocusComment, section);
  const _renderReplyForm = () => renderReplyForm(
    canReply,
    defaultNickname,
    hearingId,
    language,
    user,
    nicknamePlaceholder,
    section,
    sectionCommentId,
    onPostReply,
    data,
    _setIsEditingReply,
    replayingText,
    _cancelReplyEditing,
    parentIdList,
  );
  const isInNewCommentChain = newCommentParentChain.includes(data.id);
  const _renderViewReplyButton = () => renderViewReplyButton(data, section, sectionCommentId, getCommentSubComments, isInNewCommentChain);
  const _renderEditLinks = () => renderEditLinks(data, onDeleteComment, onUnPublishComment, onPublishComment);

  const onEnteringIntoView = () => {
    if (newCommentIds.includes(data.id)) {
      handleCommentWasSeen(data.id);
    }
  }

  const _shouldRenderDeleteForOthers = (shouldRenderDeleteForOthers(data, user, hearing))
  const isUserAnOrgAdmin = user &&
    Array.isArray(user.adminOrganizations) &&
    user.adminOrganizations.includes(hearing.organization);

  const editButtons = [
    {
      id: 'delete',
      condition: (canEdit) || _shouldRenderDeleteForOthers,
    }, {
      id: 'edit',
      condition: (isOwnComment && canEdit),
    }, {
      id: 'unpublish',
      condition: (isUserAnOrgAdmin || _shouldRenderDeleteForOthers) && data.published,
    },  {
      id: 'publish',
      condition: (isUserAnOrgAdmin || _shouldRenderDeleteForOthers) && !data.published,
    },
  ].filter( eb => eb.condition ).map( eb => eb.id )

  return (
    !data.content
      ? <></>
      : (
        <li
          data-aos-once="true"
          className={classnames([
            'hearing-comment',
            {
              'comment-reply': isReply,
              'hearing-comment__has-replys': hasSubComments,
              'hearing-comment__admin': isAuthorAdminUser(),
              'hearing-comment__is-pinned': data.pinned,
              'hearing-comment__unpublished': !data.published,
              'hearing-comment__is_new_comment': newCommentIds.includes(data.id),
            },
            className
          ])}
          ref={commentRef}
        >
          <div className={classNames(
            "hearing-comment__comment-wrapper",
            {
              "hearing-comment__ancestor": isAncestor,
              'hearing-comment__own': isOwnComment,
            }
          ) }>
            {renderCommentHeader(
              isOwnComment, data, newCommentIds, parentIdList.length,
            )}
            <div className="hearing-comment-main">
            
            <Sticker stickerId={data.sticker} />
            {
              newCommentIds.includes(data.id)
              ? (
                <IntersectionObserverContainer id={data.id} onEnteringIntoView={onEnteringIntoView} delay={15000}>
                  <CommentBody data={data} />
                </IntersectionObserverContainer>
              )
              : (
                <CommentBody data={data} />
              )
            }
            <CommentImageComponent images={data.images} />
            <CommentFooterComponent
              canReply={!isReplyEditorOpen && canReply && !data.deleted}
              votesNum={data.n_votes}
              handleToggleReplyEditor={(e) => {
                handleToggleReplyEditor(e, data, section, sectionCommentId, getCommentSubComments);
              }}
              votedByUser={data.voted_by_user}
              onVote={() => onVote(canVote, onPostVote, data, sectionCommentId)}
              showVotes={!data.deleted}
              showEditToggler={editButtons.length > 0}
              editButtons={editButtons}
              handleEdit={toggleEditor}
              handleDelete={event => handleDelete(event, data, onDeleteComment)}
              onUnPublishComment={event => handleUnPublish(event, data, sectionCommentId, onUnPublishComment)}
              onPublishComment={event => handlePublish(event, data, sectionCommentId, onPublishComment)}
              isRepliesOpen={isRepliesOpen}
              renderHideReplyButton={() => renderHideReplyButton(data, isInNewCommentChain)}
              renderViewReplyButton={() => renderViewReplyButton(data, section, sectionCommentId, getCommentSubComments, isInNewCommentChain)}
              isLastOpen={openComments.slice(-1).includes(data.id)}
            />
            {editorOpen && _renderEditorForm()}
            {isReplyEditorOpen && _renderReplyForm()}
            
              
            </div>
          </div>
          {hasSubComments &&
            isRepliesOpen &&
            renderSubComments()}
        </li>
      )
  );
};


const mapDispatchToProps = dispatch => ({
  onEditComment: (hearingSlug, sectionId, commentId, sectionCommentId, commentData) =>
    dispatch(
      editSectionComment(hearingSlug, sectionId, commentId, sectionCommentId, commentData),
    ),
  setIsEditingReply: (commentId, sectionId, value) =>
    dispatch(
      setIsEditingReply(commentId, sectionId, value),
    ),
  removeEditingReply: (commentId, sectionId) =>
    dispatch(
      removeEditingReply(commentId, sectionId),
    ),
  cleanJumpTo: (sectionId) =>
    dispatch(cleanJumpTo(sectionId)),
});

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  language: state.language,
  isEditing: getCommentEditingStateById(state, ownProps.data.id, ownProps.section.id),
  replayingText: getReplyEditingTextById(state, ownProps.data.id, ownProps.section.id),
});

Comment.propTypes = {
  canReply: PropTypes.bool,
  canVote: PropTypes.bool,
  data: PropTypes.object,
  defaultNickname: PropTypes.string,
  hearing: PropTypes.object,
  hearingId: PropTypes.string,
  isReply: PropTypes.bool,
  jumpTo: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  nicknamePlaceholder: PropTypes.string,
  onDeleteComment: PropTypes.func,
  onUnPublishComment: PropTypes.func,
  onPublishComment: PropTypes.func,
  onPostReply: PropTypes.func,
  onPostVote: PropTypes.func,
  parentComponentId: PropTypes.number,
  questions: PropTypes.array,
  sectionCommentId: PropTypes.number,
  section: PropTypes.object,
  user: PropTypes.object,
  language: PropTypes.string,
  onEditComment: PropTypes.func,
  getCommentSubComments: PropTypes.func,
  removeUrlFocusComment: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsEditingReply: PropTypes.func,
  replayingText: PropTypes.string,
  removeEditingReply: PropTypes.func,
  newCommentParentChain: PropTypes.array,
  newCommentIds: PropTypes.array,
  handleCommentWasSeen: PropTypes.func,
  cleanJumpTo: PropTypes.func,
};

Comment.defaultProps = {
  isReply: false,
};

export { Comment as UnconnectedComment };
const ConnectedComment = connect(mapStateToProps, mapDispatchToProps)(Comment);
export default ConnectedComment;

Comment.displayName = 'Comment';