import PropTypes from 'prop-types';
import { FormattedMessage} from 'react-intl';
import React from 'react';
import CommentUserIcon from './Comment/CommentUserIcon';
import CommentAuthorAndDate from './Comment/CommentAuthorAndDate';
import CommentPinnedIcon from './Comment/CommentPinnedIcon';
import CommentNewReleaseIcon from './Comment/CommentNewReleaseIcon';

const CommentHeaderComponent = ({
                                  isOwnComment,
                                  isAuthorAdminUser,
                                  commentData,
                                  isNewComment,
                                  parentIdListLength = 0
                                }) => {


  return (
    <div className="hearing-comment-header">
      
      <div className="hearing-comment-header__title">
        
        <div>
          <h4 className="hearing-comment-type">
          {(parentIdListLength > 0)
          ? (<FormattedMessage id="replyLevelN" values={{n:parentIdListLength+1}} />)
          : ( !!commentData.root_comment_id 
            ? (<FormattedMessage id="aReply" />) 
            : (<FormattedMessage id="baseComment" />)
          )}
          </h4>
        </div>
        
        {commentData.pinned && <CommentPinnedIcon/>}
        {isNewComment && <CommentNewReleaseIcon/>}
        
      </div>
      
      <div className="hearing-comment-publisher">
        <CommentUserIcon authorRole={isAuthorAdminUser ? 'admin' : 'user'} />
        <CommentAuthorAndDate 
          isOwnComment={isOwnComment}
          isAuthorAdminUser={isAuthorAdminUser}
          commentData={commentData}
        />
      </div>

      {!commentData.published &&
      <div className="hearing-comment-header-unpublished">
        <FormattedMessage id="unpublished" />
      </div>
      }

    </div>
  );
};

CommentHeaderComponent.propTypes = {
  toggleEditor: PropTypes.func,
  handleDelete: PropTypes.func,

  isAdminUser: PropTypes.bool,
  isOwnComment: PropTypes.bool,
  commentData: PropTypes.object,
  isNewComment: PropTypes.bool,
  
  isRepliesOpen: PropTypes.bool,
  renderHideReplyButton: PropTypes.func,
  renderViewReplyButton: PropTypes.func,
};

export default CommentHeaderComponent;