import { FormattedMessage } from 'react-intl';
import iconMetroPin from '/assets/images/icon-metro-pin.svg';
import iconUnpin from '/assets/images/unpin-image.svg';
import { Button } from 'react-bootstrap';
import getMessage from '../utils/getMessage';
import classnames from 'classnames';

const PinUnpinButton = ({
  isPinned,
  handleTogglePin
}) => {

  return (
    <div className="hearing-comment__pin">
      <span>
        <FormattedMessage id={ isPinned ? "pinnedYes" : "pinnedNo"} />
      </span>
      <Button
        title={getMessage(isPinned ? "pinnedUnpin" : "pinnedPin")}
        className={classnames([
          'hearing-comment__pin__icon pin-comment',
          {
          },
        ])}
        onClick={handleTogglePin}
      >
        <img
          src={!isPinned ? iconMetroPin : iconUnpin}
        />
      </Button>
    </div>
  );
}

export default PinUnpinButton;