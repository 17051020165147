import NewReleasesIcon from '@mui/icons-material/NewReleases';

const CommentNewReleaseIcon = () => {

  return (
    <div className='hearing-comment-new-width-container'>
    <div className='hearing-comment-absolute-container'>
      <NewReleasesIcon sx={{
        color: '#ffd54f',
        width: '34.999',
        height: '34.999',
        marginLeft: '5px'
      }} />
    </div>
  </div>
  )
}

export default CommentNewReleaseIcon;