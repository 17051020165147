import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { GlobalStyles, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { withRouter } from 'react-router-dom';
import Login from '../../views/Auth/Login';
import getMessage from '../../utils/getMessage';
import Icon from '../../utils/Icon';
import useWindowSize from '../../common/useWindowSize';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const UserMenu = ({ user, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onUserInfoClick = () => {
    history.push({
      pathname: '/user-info',
    });
    handleClose();
  };

  const { width } = useWindowSize();
  const boostrapMdBreakPoint = 992;
  return (
    width > boostrapMdBreakPoint ?
      <React.Fragment
        key="user-menu-fragment"
      >
        <GlobalStyles
          styles={{ font: 'normal normal bold 18px PT Sans' }}
        />
        <Button
          bsClass='digi-button'
          id="user-menu-button"
          role="button"
          aria-haspopup="true"
          aria-expanded={open}
          aria-label={getMessage('userMenuLabel')}
          aria-description={getMessage('userMenuDescription')}
          onClick={handleClick}
          style={{ height: '45px', width: '45px', borderRadius: '50%', boxSizing: 'borderBox', position: 'relative' }}
        >
          <div style={{position: 'absolute', width: '100%', height: '100%', top: '0', left: '0'}}>
            <div style={{position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
              {user?.username?.charAt(0).toUpperCase()}
            </div>
          </div>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            key={getMessage('userInfoLabel')}
            style={{ font: 'normal normal 14px PT Sans' }}
            aria-label={getMessage('userInfoLabel')}
            onClick={onUserInfoClick}
          >
            <div>
              {user.nickname}<br />{user.email}
              {user.adminOrganizations.map((org) => (
              <div key={`usermenuOrg${org}`}>
                {org}
              </div>
              ))}
            </div>
          </MenuItem>
          <MenuItem
            key={getMessage('ownProfile')}
            style={{ font: 'normal normal bold 16px PT Sans' }}
            aria-label={getMessage('userInfoPageHeader')}
            role="button"
            onClick={onUserInfoClick}
          >
            <Icon
              name="user"
              style={{ marginRight: '10px' }}
            />
            {getMessage('userInfoPageHeader')}
          </MenuItem>

          <MenuItem
            key={getMessage('logout')}
            style={{ font: 'normal normal bold 16px PT Sans' }}
            aria-label={getMessage('logout')}
            role="button"
            onClick={Login.logout}
          >
            <Icon
              name="sign-out"
              style={{ marginRight: '10px' }}
            /> {getMessage('logout')}
          </MenuItem>
        </Menu>
      </React.Fragment>
      :
      <>
        <MenuItem
          key={getMessage('ownProfile')}
          class="nav-link"
          role="button"
          aria-label={getMessage('userInfoLabel')}
          onClick={onUserInfoClick}
        >
          <FormattedMessage id="userInfoPageHeader" />
        </MenuItem>
        <MenuItem
          key={getMessage('logout')}
          class="nav-link"
          role="button"
          style={{ padding: '10px 15px' }}
          aria-label={getMessage('logout')}
          onClick={Login.logout}
        >
          <FormattedMessage
            id="logout"
          />
        </MenuItem>
      </>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(UserMenu);