import getMessage from "../../../../../../utils/getMessage";

const CommentPinnedIcon = () => {
  return (
    <div className="hearing-comment-pinned-container" 
      style={{backgroundImage:"url(/assets/images/icon-metro-pin.svg)"}}
      title={getMessage("pinnedComment")}>
      <span className="sr-only">
        {getMessage("pinnedComment")}
      </span>
    </div>
  )
}

export default CommentPinnedIcon;