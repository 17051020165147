import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../utils/Icon';
import getMessage from '../utils/getMessage';

const CloseButton = ({ closeToast }) => (
  <button type="button" 
    className="digi-button toast-close-button" 
    onClick={closeToast}
    aria-label={getMessage('close')}
    >
    <Icon name="times"/>
  </button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

export default CloseButton;
