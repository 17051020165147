import PropTypes from 'prop-types';
import { Button, FormGroup } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Sticker from './Sticker';
import { FormattedMessage } from 'react-intl';
import DigiraatiEmojiInput from '../../../../EmojiInput';
import { editSectionComment } from '../../../../../store/actions';
import { setIsEditingComment } from '../../../../../store/actions/commentEditorActions';
import { connect } from 'react-redux';
import { getCommentEditingTextById } from '../../../../../store/selectors/commentEditorSelectors';
import iconMetroPin from '/assets/images/icon-metro-pin.svg';
import iconUnpin from '/assets/images/unpin-image.svg';
import getMessage from '../../../../../utils/getMessage';
import PinUnpinButton from '../../../../PinUnpinButton';

const CommentEditorFormComponent = ({
                                      handleSubmit,
                                      handleTogglePin,
                                      commentText,
                                      hasPinUnpinButton,
                                      isPinned,
                                      sticker,
                                      sectionId,
                                      commentId,
                                      setIsEditing,
                                      editingValue,
                                      toggleEditor,
                                    }) => {

  const [value, setValue] = useState(commentText);
  const [editSticker, setEditSticker] = useState(sticker);
  const textAreaRef = useRef();
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (!!editingValue && editingValue !== value) {
      setValue(editingValue);
      setDirty(true);
    }
  }, [editingValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (dirty && value !== editingValue) {
        setIsEditing(commentId, sectionId, value);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [dirty, value]);

  /**
   * When an admin user is logged in and editing their comment.
   * Allow the user to pin and unpin a comment.
   */
  const renderPinUnpinButton = () => {
    return (
      <div className="hearing-comment__pin">
        <span>
          <FormattedMessage id={ isPinned ? "pinnedYes" : "pinnedNo"} />
        </span>
        <Button
          title={getMessage(isPinned ? "pinnedUnpin" : "pinnedPin")}
          className={classnames([
            'hearing-comment__pin__icon',
            {
              'hearing-comment__pin__pin-comment': !isPinned,
              'hearing-comment__pin__unpin-comment': isPinned,
            },
          ])}
          onClick={handleTogglePin}
        >
          <img
            src={!isPinned ? iconMetroPin : iconUnpin}
          />
        </Button>
      </div>
    );
  };

  const addEmojiToCommentSelectionStart = (emoji) => {
    const textArea = textAreaRef.current;
    const cursorPosition = textArea.selectionStart;
    const commentText = value;
    const textBefore = commentText.slice(0, cursorPosition);
    const textAfter = commentText.slice(cursorPosition);
    setValue(textBefore + emoji + textAfter);
  };

  const selectSticker = (stickerId) => {
    setEditSticker(stickerId);
  };

  return (
    <fieldset className="hds-fieldset">
      {hasPinUnpinButton && <PinUnpinButton isPinned={isPinned} handleTogglePin={handleTogglePin} />}
      <legend>
        <FormattedMessage id="edit" />
      </legend>
      <form
        className="hearing-comment__edit-form"
        onSubmit={(event) => handleSubmit(event, value, editSticker)}
      >
        {
          editSticker &&
          <div>
            <Sticker stickerId={editSticker} />
            <Button onClick={() => selectSticker(null)}>
              <FormattedMessage id="delete" />
            </Button>
          </div>
        }
        <FormGroup controlId="formControlsTextarea">
            <textarea
              ref={textAreaRef}
              className="form-control"
              placeholder="textarea"
              value={value}
              onChange={event => {
                setDirty(true);
                setValue(event.target.value);
              }}
            />
        </FormGroup>
        <div className="hearing-comment__edit-form__buttons">
          <button type="button" onClick={toggleEditor} className="digi-button">
            <FormattedMessage id="cancel" />
          </button>
          <button type="submit" className="digi-button">
            <FormattedMessage id="save" />
          </button>
        </div>
      </form>
    </fieldset>
  );
};

CommentEditorFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  handleTogglePin: PropTypes.func,
  commentText: PropTypes.string,
  hasPinUnpinButton: PropTypes.bool,
  isPinned: PropTypes.bool,
  sticker: PropTypes.string,
  sectionId: PropTypes.string,
  commentId: PropTypes.number,
  setIsEditing: PropTypes.func,
  editingValue: PropTypes.string,
};

const mapStateToProps = (state, { sectionId, commentId }) => ({
  editingValue: getCommentEditingTextById(state, commentId, sectionId),
});

const mapDispatchToProps = dispatch => ({
  onEditComment: (hearingSlug, sectionId, commentId, sectionCommentId, commentData) =>
    dispatch(
      editSectionComment(hearingSlug, sectionId, commentId, sectionCommentId, commentData),
    ),
  setIsEditing: (commentId, sectionId, value) =>
    dispatch(setIsEditingComment(commentId, sectionId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditorFormComponent);

CommentEditorFormComponent.displayName = 'CommentEditorFormComponent';