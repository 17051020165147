
import { IconButton } from '@mui/material';
import hearingJoinRequestSvg from '../../../assets/images/hearing-join-request.svg';
import { StyledBadge } from './HearingCard';

const HearingInformationJoinRequests = ({ hearing, history, marginLeft = '20px' }) => {
  
  if (!(hearing.can_access_detail && hearing.active_join_requests_num > 0)) {
    return <></>
  }

  return (
    <IconButton
      aria-label="active join requests notifications"
      style={{ marginLeft: marginLeft }}
      onClick={() =>
        history.push({
          pathname: `/${hearing.slug}/manage/hearing-join-requests`,
        })
      }
    >
      <StyledBadge
        badgeContent={hearing.active_join_requests_num}
        color="warning"
        max={99}
      >
        <img className="hearing-image" src={hearingJoinRequestSvg} />
      </StyledBadge>
    </IconButton>
  );
};

export default HearingInformationJoinRequests;
