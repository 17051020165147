import { notifyError } from '../../../../../utils/notify';
import forEach from 'lodash/forEach';
import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import CommentHeaderComponent from './CommentHeaderComponent';
import CommentEditorFormComponent from './CommentEditorFormComponent';
import { FormattedMessage } from 'react-intl';
import CommentForm from '../../../../BaseCommentForm';
import ShowMore from './ShowMore';
import HideComments from './HideComments';
import { includes } from 'lodash';

const useComment = (openComments, setOpenComments, commentId, parentIdList) => {

  const [answers, setAnswers] = useState([]);
  const [pinned, setPinned] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [shouldJumpTo, setShouldJumpTo] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [scrollComplete, setScrollComplete] = useState(false);
  //const [isRepliesOpen, setIsRepliesOpen] = useState( !!openComments ? openComments.includes(commentId) : false);
  const [isReplyEditorOpen, setIsReplyEditorOpen] = useState(false);
  const [mapContainer, setMapContainer] = useState(null);
  const [displayMap, setDisplayMap] = useState(false);
  const [showEditButtons, setShowEditButtons] = useState(false);

  const isRepliesOpen = !!openComments && openComments.includes(commentId);

  useEffect(() => {
    if (isReplyEditorOpen) {
      if (openComments.slice(-1)[0] !== commentId) {
        setIsReplyEditorOpen(false);
      }
    }
  }, [openComments])

  /** Is the comment by an admin user */
  const isAuthorAdminUser = (data) =>
    data &&
    (typeof data.organization === 'string' ||
      Array.isArray(data.organization));

  const onVote = (canVote, onPostVote, data, sectionCommentId) => {
    if (canVote) {
      onPostVote(
        data.id,
        data.section,
        sectionCommentId,
      );
    } else {
      notifyError('Kirjaudu sisään äänestääksesi kommenttia.'); // TODO: Translation to sv
    }
  };

  const toggleShowEditButtons = () => {
    setShowEditButtons(!showEditButtons);
  }

  const handleSubmit = (event, value, sticker, data, sectionCommentId, hearing, onEditComment, removeUrlFocusComment) => {
    event.preventDefault();

    const { section, id } = data;
    const commentData = {};

    forEach(data, (value, key) => {
      if (['content', 'images'].indexOf(key) === -1) {
        commentData[key] = value;
      }
    });
    commentData.content = value;
    if (data.can_edit && isAuthorAdminUser(data)) {
      commentData.pinned = pinned;
    }
    commentData.answers = answers;
    commentData.sticker = sticker;
    onEditComment(hearing.slug, section, id, sectionCommentId, commentData);
    removeUrlFocusComment();
    setEditorOpen(false);

  };

  const handleDelete = (event, data, onDeleteComment) => {
    event.preventDefault();
    const { section, id, author_name, content } = data;
    onDeleteComment(section, id, author_name, content);
  };

  const handleUnPublish = (event, data, sectionCommentId, onUnPublishComment) => {
    event.preventDefault();
    const { section, id } = data;
    onUnPublishComment(section, sectionCommentId, id);
  };

  const handlePublish = (event, data, sectionCommentId, onPublishComment) => {
    event.preventDefault();
    const { section, id } = data;
    onPublishComment(section, sectionCommentId, id);
  };

  const openThisComment = () => {
    setOpenComments(parentIdList.concat(commentId))
  }

  /**
   * Open reply editor
   */
  const handleToggleReplyEditor = (event, data, section, sectionCommentId, getCommentSubComments) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (!isReplyEditorOpen) {
      handleShowReplies(data, section, sectionCommentId, getCommentSubComments)
    }
    setIsReplyEditorOpen(!isReplyEditorOpen);
  };

  /**
   * Call the parent component to retrieve list of sub comments for current comment.
   */
  const handleShowReplies = (data, section, sectionCommentId, getCommentSubComments) => {
    //setIsRepliesOpen(true);
    if (!data.subComments) {
      getCommentSubComments(data.id, sectionCommentId, section.id);
    }
    openThisComment();
  };
  const getStrigifiedAnswer = (answer, questions, intl) => {

    const question = find(questions, que => que.id === answer.question); // eslint-disable-line
    let selectedOption = {};
    return {
      question: question ? getAttr(question.text, intl.locale) : '',
      answers: answer.answers.map(ans => {
        if (question) {
          selectedOption = find(question.options, option => option.id === ans);
        }
        return question ? getAttr(selectedOption.text, intl.locale) : '';
      }),
    };
  };

  /**
   * Handle posting of a reply
   */
  const handlePostReply = (
    text, authorName, pluginData, geojson, label, images, _pinned, 
    _mapCommentText, sticker, section, sectionCommentId, onPostReply, data,
    parentIdList,
  ) => {
    let commentData = { text, authorName, pluginData, geojson, label, images, sticker };
    if (onPostReply && onPostReply instanceof Function) {
      commentData = { 
        ...commentData, 
        comment: data.id, 
        parentIdList: parentIdList.concat(data.id) 
      };
      onPostReply(section.id, sectionCommentId, { ...commentData });
      setOpenComments(parentIdList.concat(commentId))
      setIsReplyEditorOpen(false);
    }
  };

  const toggleEditor = (event) => {
    event.preventDefault();

    if (editorOpen) {
      setEditorOpen(false);
    } else {
      setEditorOpen(true);
    }
  };

  /**
   * Toggle the pinning of comment
   */
  const handleTogglePin = () => {
    setPinned((prevState) => !prevState);
  };

  /**
   * Renders the header area for the comment
   * @returns {Component}
   */
  const renderCommentHeader = (isOwnComment, data, newCommentIds, parentIdListLength) => {
    return <CommentHeaderComponent
      isAuthorAdminUser={isAuthorAdminUser(data)}
      isOwnComment={isOwnComment}
      commentData={data}
      isNewComment={newCommentIds.includes(data.id)}
      parentIdListLength={parentIdListLength}
    />
  };

  /**
   * When state is set to true for editor open. Return the form.
   * When editing, answers may be edited as well.
   * @returns {Component}
   */
  const renderEditorForm = (data, isReply, sectionCommentId, hearing, onEditComment, removeUrlFocusComment, section) => (
    <CommentEditorFormComponent
      commentText={data.content}
      handleSubmit={(event, value, editSticker) => handleSubmit(event, value, editSticker, data, sectionCommentId, hearing, onEditComment, removeUrlFocusComment)}
      hasPinUnpinButton={isAuthorAdminUser(data) &&
        data.can_edit &&
        !isReply}
      isPinned={pinned}
      handleTogglePin={handleTogglePin}
      sticker={data.sticker}
      sectionId={section.id}
      commentId={data.id}
      toggleEditor={toggleEditor}
    />
  );

  /**
   * If a user can edit their comment(s) render hyperlinks
   * @returns {Component|null}
   */
  const renderEditLinks = (data, onDeleteComment, onUnPublishComment, onPublishComment) => (
    <div className="hearing-comment__edit-links">
      <a
        href=""
        onClick={event => toggleEditor(event)}
      >
        <FormattedMessage id="edit" />
      </a>
      <a
        href=""
        onClick={event => handleDelete(event, data, onDeleteComment)}
      >
        <FormattedMessage id="delete" />
      </a>
      <a
        href=""
        onClick={event => handleUnPublish(event, data, onUnPublishComment)}
      >
        <FormattedMessage id="hide" />
      </a>
      <a
        href=""
        onClick={event => handlePublish(event, data, onPublishComment)}
      >
        <FormattedMessage id="publish" />
      </a>

    </div>
  );


  const renderUnPublishButton = (isPublished, data, onUnPublishComment) => (
    isPublished &&
    <a
      href=""
      onClick={event => handleUnPublish(event, data, onUnPublishComment)}
    >
      <FormattedMessage id="hide" />
    </a>
  );

  const renderPublishButton = (isPublished, data, onPublishComment) => (
    !isPublished &&
    <a
      href=""
      onClick={event => handlePublish(event, data, onPublishComment)}
    >
      <FormattedMessage id="publish" />
    </a>
  );

  /**
   * If a user can edit their comment(s) render hyperlinks
   * @returns {Component|null}
   */
  const renderRemoveLink = (data, onDeleteComment, onUnPublishComment, onPublishComment) => (
    <div className="hearing-comment__edit-links">
      <a
        href=""
        onClick={event => handleDelete(event, data, onDeleteComment)}
      >
        <FormattedMessage id="delete" />
      </a>
      {renderUnPublishButton(data.published, data, onUnPublishComment)}
      {renderPublishButton(data.published, data, onPublishComment)}
    </div>
  );

  /**
   * When a comment is being replied to.
   * @returns {Component<Form>}
   */
  const renderReplyForm = (
    canReply,
    defaultNickname,
    hearingId,
    language,
    user,
    nicknamePlaceholder,
    section,
    sectionCommentId,
    onPostReply,
    data,
    setIsEditingReply,
    replayingText,
    handleCancelReply,
    parentIdList,
  ) => (
    <CommentForm
      answers={answers}
      canComment={canReply}
      defaultNickname={defaultNickname}
      isReply
      language={language}
      nicknamePlaceholder={nicknamePlaceholder}
      onChangeAnswers={() => {}}
      onOverrideCollapse={handleToggleReplyEditor}
      onPostComment={(commentText,
                      nickname,
                      pluginData,
                      geojson,
                      label,
                      images,
                      pinned,
                      mapCommentText,
                      sticker,
                    ) =>
        handlePostReply(
          commentText,
          nickname,
          pluginData,
          geojson,
          label,
          images,
          pinned,
          mapCommentText,
          sticker,
          section,
          sectionCommentId,
          onPostReply,
          data,
          parentIdList,
      )}
      section={section}
      user={user}
      hearingGeojson={data.geojson}
      setEditing={setIsEditingReply.bind(this)}
      editedText={replayingText}
      cancelEditing={handleCancelReply}
      hasDelayToSaveType={false}
    />
  );

  /**
   * Renders the button when clicked shows replys posted for a specific comment.
   */
    // eslint-disable-next-line no-confusing-arrow
  const renderViewReplyButton = (data, section, sectionCommentId, getCommentSubComments, isInNewCommentChain) => {
      return data.all_sub_comments &&
      Array.isArray(data.all_sub_comments) &&
      data.all_sub_comments.length > 0 ? (
        <ShowMore
          numberOfComments={data.all_sub_comments.length}
          onClickShowMore={() => handleShowReplies(data, section, sectionCommentId, getCommentSubComments)}
          isLoadingSubComment={data.loadingSubComments}
          isInNewCommentChain={isInNewCommentChain}
        />
      ) : <div className="hearing-comment__replies-empty">
        <FormattedMessage id='repliesEmpty' />
      </div>;
    };

  /**
   * Call the parent component to retrieve list of sub comments for current comment.
   */
  const handleHideReplys = () => {
    //setIsRepliesOpen(false);
    const commentIdIndex = openComments.findIndex(oc => oc === commentId)
    setOpenComments(openComments.slice(0, commentIdIndex));
  };

  /**
   * Renders the button when clicked hides replys posted for a specific comment.
   */
    // eslint-disable-next-line no-confusing-arrow
  const renderHideReplyButton = (data, isInNewCommentChain) => {
      return data.all_sub_comments &&
      Array.isArray(data.all_sub_comments) &&
      data.all_sub_comments.length > 0 ? (
        <HideComments
          numberOfComments={data.all_sub_comments.length}
          onClickHideComments={handleHideReplys}
          isLoadingSubComment={data.loadingSubComments}
          isInNewCommentChain={isInNewCommentChain}
        />
      ) : null;
    };

  const handleSetMapContainer = mapContainer => {
    setMapContainer(mapContainer);
  };

  const toggleMap = () => {
    setDisplayMap((prevState) => !prevState);
  };

  const shouldRenderDeleteForOthers = (data, user, hearing) => {
    const isNotOwnMessage = !data.can_edit;
    const isAdminUser = Boolean(
      user &&
      includes(
        user.adminOrganizations || [],
        hearing.organization,
      ),
    );
    const commentNotDeleted = !data.deleted
    const isFacilitator = hearing.user_is_facilitator;
    const isSuperuser = user.is_superuser;
    return commentNotDeleted && isNotOwnMessage && (isFacilitator || isAdminUser || isSuperuser);
  };

  return {
    isAuthorAdminUser,
    onVote,
    pinned, setPinned,
    answers, setAnswers,
    editorOpen, setEditorOpen,
    shouldJumpTo, setShouldJumpTo,
    shouldAnimate, setShouldAnimate,
    scrollComplete, setScrollComplete,
    isRepliesOpen,// setIsRepliesOpen,
    isReplyEditorOpen, setIsReplyEditorOpen,
    mapContainer, setMapContainer,
    displayMap, setDisplayMap,
    handleSubmit,
    handleDelete,
    handleUnPublish,
    handlePublish,
    handleToggleReplyEditor,
    getStrigifiedAnswer,
    handlePostReply,
    toggleEditor,
    renderCommentHeader,
    handleTogglePin,
    renderEditorForm,
    renderEditLinks,
    renderRemoveLink,
    renderReplyForm,
    renderViewReplyButton,
    renderHideReplyButton,
    handleSetMapContainer,
    toggleMap,
    shouldRenderDeleteForOthers,
    showEditButtons,
    toggleShowEditButtons,
  };


};

export default useComment;