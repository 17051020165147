import { DateTime } from "luxon"
import getMessage from "../../utils/getMessage"
import calendarfilled from '../../../assets/images/calendarfilled.svg';
import classNames from "classnames";

const HearingInformationDates = ({
  hearing, 
  stateAlignRight = true
}) => {
  const openingDateTime = DateTime.fromISO(hearing.open_at)
  const closingDateTime = DateTime.fromISO(hearing.close_at)
  const openingTimeFormat = (openingDateTime.year === closingDateTime.year) ? 'dd.MM' : 'dd.MM.yyyy'
  const openingTime = openingDateTime.toFormat(openingTimeFormat+' HH:mm');
  const closingTime = closingDateTime.toFormat('dd.MM.yyyy HH:mm');

  const now = Date.now();
  const nowIsBetweenTimes = now > openingDateTime.toMillis() && now < closingDateTime.toMillis();
  let hearingState = 'hearingClosed';
  if (hearing.published && nowIsBetweenTimes) {
    hearingState = 'published';
  } else if (!hearing.published) {
    hearingState = 'draft'
  }

  classNames

  return (
    <div>
      <div className="hearing-card-date">
        <img
          className="hearing-image"
          src={calendarfilled}
          style={{ color: '#7E23E7' }}
          alt={getMessage('hearingSchedule')}
        />
        <div><span className='dateSpan'>{openingTime}</span> <span className='dateSpan'>&nbsp;-&nbsp;{closingTime}</span></div>
      </div>
      <div>
        <div className={classNames(
          'hearing-state',
          hearingState,
          {'align-right': stateAlignRight}
        )}>{getMessage(hearingState)}</div>
      </div>
    </div>
  )
}

export default HearingInformationDates;