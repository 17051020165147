import { FormattedMessage } from 'react-intl';
import HearingInformationDates from './HearingInformationDates';
import HearingInformationCommentCount from './HearingInformationCommentCount';
import HearingInformationJoinRequests from './HearingInformationJoinRequests';

const HearingCardInformation = ({hearing, history}) => {


  return (
    <div className="hearing-card-comments">
      <div>
        <HearingInformationCommentCount hearing={hearing} />
        </div>
        {hearing.can_access_detail && hearing.active_join_requests_num > 0 &&
          <HearingInformationJoinRequests hearing={hearing} history={history} />
        }
      <div className="hearing-card-user-info">
        
        {/* TODO: Possibly add later the info about how many persons there are in the hearing */}
        {/* <img className="hearing-image" src={person} style={{ width: '20px', color: '#7E23E7' }}/> */}
        
      </div>
      <HearingInformationDates hearing={hearing} />
      
      <span className="sr-only">
        {hearing.n_comments === 1 ? (
          <FormattedMessage id="hearingCardComment" />
        ) : (
          <FormattedMessage id="hearingCardComments" />
        )}
      </span>
    </div>
  );

}

export default HearingCardInformation;