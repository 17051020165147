/* eslint-disable react/no-did-mount-set-state */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import Icon from '../utils/Icon';
import { localizedNotifyError } from '../utils/notify';
import { getSectionCommentingErrorMessage } from '../utils/section';
// eslint-disable-next-line import/no-unresolved
import urls from '@city-assets/urls.json';
// eslint-disable-next-line import/no-unresolved
import localization from '@city-i18n/localization.json';
import leafletMarkerIconUrl from '../../assets/images/leaflet/marker-icon.png';
import Leaflet from 'leaflet';
import leafletMarkerShadowUrl from '../../assets/images/leaflet/marker-shadow.png';
import leafletMarkerRetinaIconUrl from '../../assets/images/leaflet/marker-icon-2x.png';
import Sticker from './Hearing/sortable-comment-list/comment-list/comment/Sticker';
import getMessage from '../utils/getMessage';
import PinUnpinButton from './PinUnpinButton';
import CommentFormToolsPopup from './CommentFormToolsPopup';
import classNames from 'classnames';


Leaflet.Marker.prototype.options.icon = new Leaflet.Icon({
  iconUrl: leafletMarkerIconUrl,
  shadowUrl: leafletMarkerShadowUrl,
  iconRetinaUrl: leafletMarkerRetinaIconUrl,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
});

const BaseCommentForm = ({
  defaultNickname = '',
  editedText,
  cancelEditing,
  collapseForm,
  onOverrideCollapse = () => {},
  hasDelayToSaveType = false,
  setEditing,
  nicknamePlaceholder,
  user,
  hideNameForm,
  canComment, 
  section,
  hearingGeojson,
  isHighContrast, 
  language,
  isReply = false, 
  allowFiles = true,
  onPostComment,
}) =>  {
  const id = Math.random().toString(36).slice(2, 7);
  const [storedTimeout, setStoredTimeout] = useState(null)

  const [collapsed, setCollapsed] = useState(true);
  const [commentText, setCommentText] = useState('');
  const [nickname, setNickname] = useState(defaultNickname || '');
  const [imageTooBig, setImageTooBig] = useState(false);
  const [images, setImages] = useState([]);
  const [pinned, setPinned] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [hideName, setHideName] = useState(false);
  const [sticker, setSticker] = useState(null);

  const [formToolsOpen, setFormToolsOpen] = useState(null)

  // The shady ones
  const [geojson, setGeojson] = useState({});
  const [mapCommentText, setMapCommentText] = useState('')

  const commentTextFieldRef = useRef();

  useEffect(() => {
    if (isUserAdmin()) {
      setNickname(defaultNickname);
    }
    if (!!editedText && editedText !== commentText) {
      setCommentText(editedText);
    }
  }, [])

  useEffect(() => {
    if (collapseForm) {
      clearCommentText();
      toggle();
    }
  }, [collapseForm])

  const clearState = () => {
    setCollapsed(!collapsed);
    setCommentText('');
    setNickname(defaultNickname || '');
    setImageTooBig(false);
    setImages([]);
    setPinned(false);
    setShowAlert(true);
    setHideName(false);
    setMapCommentText('');
    setSticker(null);
    setFormToolsOpen(null)
  }

  const toggle = () => {
    if (canComment) {
      cancelEditing();
      clearState();
      if (onOverrideCollapse instanceof Function) {
        onOverrideCollapse();
      }
    } else {
      localizedNotifyError(getSectionCommentingErrorMessage(section));
    }
  }

  const handleTextChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setCommentText(event.target.value);
    if (hasDelayToSaveType) {
      const COMMENT_POLL_INTERVAL_IN_MS = 1000;
      clearTimeout(storedTimeout);
      const time = setTimeout(() => {
        if (commentText !== editedText) {
          setEditing(commentText);
        }
      }, COMMENT_POLL_INTERVAL_IN_MS);
      setStoredTimeout(time);
    } else {
      if (event.target.value !== editedText) {
        setEditing(event.target.value);
      }
    }
  }

  /**
   * @deprecated
   */
  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  }

  const clearCommentText = () => {
    setCommentText('');
  }

  const submitComment = () => {
    let _pluginData = undefined; // getPluginData();
    let _nickname =
      nickname === ''
        ? nicknamePlaceholder
        : nickname;
    let _commentText =
      commentText === null ? '' : commentText;
    let _geojson = geojson;
    let _label = null;
    let _images = images;
    let _pinned = pinned;
    let _mapCommentText = mapCommentText;
    let _sticker = sticker;
    onPostComment(
      _commentText,
      _nickname,
      _pluginData,
      _geojson,
      _label,
      _images,
      _pinned,
      _mapCommentText,
      _sticker,
    );
    clearState();
  }


  /**
   * Determines whether the logged in user is admin or not.
   * The array in users with key adminOrganizations should be of length > 0
   */
  const isUserAdmin = () =>
    user &&
    Array.isArray(user.adminOrganizations) &&
    user.adminOrganizations.length > 0;


  /**
   * When user type is admin, an alert is shown, use this method to close the alert.
   */
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  /**
   * When logged in as admin, user may chose to hide their identity.
   */
  const handleToggleHideName = () => {
    setNickname(!hideName ? getMessage('employee') : defaultNickname);
    setHideName(!hideName);
  };

  /**
   * Toggle the pinning of comment
   */
  const handleTogglePin = () => {
    setPinned(!pinned);
  };


  const getId = (prefix) => {
    return prefix + '-' + id;
  }

  const setCommentSticker = (stickerId) => {
    setSticker(stickerId);
  }

  const setOrToggleFormTools = (tools) => {
    if (formToolsOpen === tools) {
      setFormToolsOpen(null); // toggle off
    } else {
      setFormToolsOpen(tools); // open
    }
  }

  return (
    <div className="comment-form">
      <form>
        <div className="comment-form__heading-container">
          {!!formToolsOpen && 
            <CommentFormToolsPopup
              formToolsOpen={formToolsOpen} 
              setFormToolsOpen={setFormToolsOpen}
              setCommentSticker={setCommentSticker}
              sticker={sticker}
              images={images}
              setImages={setImages}
              imageTooBig={imageTooBig}
              setImageTooBig={setImageTooBig}
              getId={getId}
              commentTextFieldRef={commentTextFieldRef}
              isUserAdmin={isUserAdmin}
              user={user}
              hideNameForm={hideNameForm}
              commentText={commentText}
              setCommentText={setCommentText}
              showAlert={showAlert}
              handleCloseAlert={handleCloseAlert}
              hideName={hideName}
              handleToggleHideName={handleToggleHideName}
              nickname={nickname}
            />
          }
          <div className="comment-form__heading-container__title">
            <label htmlFor="commentTextField" className="h4">
              <FormattedMessage id={isReply ? "writeReply" : "writeBaseComment"} />
            </label>
            
            <button type='button' className='digi-button btn danger' onClick={toggle}>
                <FormattedMessage id="cancelComment" />
              </button>
          </div>
          {isUserAdmin() && !isReply && (
            <div className="comment-form__heading-container__pin">
              <PinUnpinButton isPinned={pinned} handleTogglePin={handleTogglePin} />
            </div>
          )}
        </div>
        {
          sticker &&
          <div>
            <Sticker stickerId={sticker} />
            <Button onClick={() => setCommentSticker(null)}>
              <FormattedMessage id="delete" />
            </Button>
          </div>
        }
        <div className='input-container'>
          <div className='textarea-container'>
            <FormControl
              inputRef={commentTextFieldRef} // This causes a "Warning: Failed prop type". May go away after updating from material-ui 4
              autoFocus
              className="comment-textarea"
              componentClass="textarea"
              value={commentText}
              onChange={handleTextChange}
              id={getId('commentTextField')}
            />
          </div>
          <div className="buttons-container">
            <div className="left-buttons">
              <button type='button' 
                className={classNames(
                  'digi-button btn',
                  {
                    'digi-button__selected': formToolsOpen === 'emoji'
                  }
                )}
                onClick={() => setOrToggleFormTools('emoji')}
                aria-label={getMessage('emojis')}
                title={getMessage('emojis')}
                >😀</button>
              {allowFiles && 
              <button type='button' 
                className={classNames(
                  'digi-button btn',
                  {
                    'digi-button__selected': formToolsOpen === 'files'
                  }
                )}
                onClick={() => setOrToggleFormTools('files')}
                style={{position: "relative"}}
                aria-label={getMessage('attachments')}
                title={getMessage('attachments')}
                ><Icon name="fa-solid fa-paperclip" />
                { (images.length > 0) && <div style={{position: "absolute", bottom:0, right: 0}}>{images.length}</div> }
              </button>}
              {isUserAdmin() && 
                <button type='button' 
                  className={classNames(
                    'digi-button btn',
                    {
                      'digi-button__selected': formToolsOpen === 'nameOrg'
                    }
                  )}
                  onClick={() => setOrToggleFormTools('nameOrg')}
                ><FormattedMessage id='nameAndOrganization' />
              </button> }
            </div>
            <div className="right-buttons">
              <button type='button' className='digi-button btn'
                disabled={!commentText || imageTooBig}
                onClick={submitComment}
                title={getMessage('submit')}
              ><Icon name="fa-solid fa-send"/></button>
            </div>
          </div>
          
        </div>
      </form>
    </div>
  );
  
}

BaseCommentForm.propTypes = {
  canComment: PropTypes.bool,
  onPostComment: PropTypes.func,
  onOverrideCollapse: PropTypes.func,
  intl: intlShape,
  collapseForm: PropTypes.bool,
  defaultNickname: PropTypes.string,
  nicknamePlaceholder: PropTypes.string,
  section: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
  isReply: PropTypes.bool,
  isHighContrast: PropTypes.bool,
  hearingGeojson: PropTypes.object,
  allowFiles: PropTypes.bool,
  hideNameForm: PropTypes.bool,
  setEditing: PropTypes.func,
  cancelEditing: PropTypes.func,
  editedText: PropTypes.string,
  hasDelayToSaveType: PropTypes.bool,
};

const mapStateToProps = state => ({
  isHighContrast: state.accessibility.isHighContrast,
});
const WrappedBaseCommentForm = connect(
  mapStateToProps,
  null,
)(injectIntl(BaseCommentForm));
export default WrappedBaseCommentForm;
